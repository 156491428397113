export const constantFn = (value) => () => value;
export const identity = (v) => v;
export const noOp = () => { };
/** Warning: this is a handy cast of anything into a promise but might have unexpected effect on Thenable objects */
export const promise = (p) => Promise.resolve(p);
export const ignoreVoidPromise = (f, ...args) => {
    promise(f(...args))
        .then(noOp)
        .catch(noOp);
};
/** Shorthand writing for "Using callback returning void which effect will not be awaited if promise" */
export const voidCb = (f, ...args) => () => ignoreVoidPromise(f, ...args);
