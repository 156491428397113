var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { Badge, IconButton, Menu, MenuItem, ListItemText } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { fetchNotifications, updateNotificationAll } from "./request";
export const NotificationsButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    useEffect(() => {
        const initNotifications = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const notificationsData = yield fetchNotifications();
                const unreadNotifications = notificationsData.filter((notification) => !notification.is_read);
                setNotifications(unreadNotifications);
                setUnreadCount(unreadNotifications.length);
            }
            catch (error) {
                console.error("Failed to initialize notifications:", error);
            }
        });
        initNotifications().catch((error) => console.error("Failed to execute initNotifications:", error));
    }, []);
    const markAsRead = (notificationId, redirectUrl) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield updateNotificationAll(notificationId, redirectUrl);
            // Refresh the notifications to reflect the change (removing the read notification from the list)
            const notificationsData = yield fetchNotifications();
            const unreadNotifications = notificationsData.filter((notification) => !notification.is_read);
            setNotifications(unreadNotifications);
            setUnreadCount(unreadNotifications.length); // Update unread count
        }
        catch (error) {
            console.error("Failed to mark notification as read:", error);
        }
    });
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (notificationId, redirectUrl) => {
        setAnchorEl(null);
        if (notificationId !== undefined) {
            markAsRead(notificationId, redirectUrl).catch((error) => console.error("Failed to execute markAsRead:", error));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { color: "inherit", size: "large", onClick: handleClick, children: _jsx(Badge, { badgeContent: unreadCount, color: "error", children: _jsx(NotificationsIcon, {}) }) }), _jsx(Menu, { id: "notifications-menu", anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: () => handleClose(), children: notifications.length > 0 ? (notifications.map((notification) => (_jsx(MenuItem, { onClick: () => handleClose(notification.id, notification.redirect_url), children: _jsx(ListItemText, { primary: notification.message, style: { fontWeight: "bold" } }) }, notification.id)))) : (_jsx(MenuItem, { onClick: () => handleClose(), children: "Aucune notification." })) })] }));
};
