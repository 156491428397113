import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardActions, CardContent, Dialog, DialogContent, Grid, TextField, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MicrosoftLogo } from "../common/assets/microsoft-logo";
import { keyboardCallback } from "../common/util/key";
import { reloadPage } from "../common/web/util";
import { loginUser } from "./request";
export function CustomLogin() {
    const [open, setOpen] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    useEffect(() => {
        keyboardCallback("unephrasebiencachee", () => setOpen(true));
    }, []);
    const updateUsername = (event) => {
        setUsername(event.target.value);
    };
    const updatePassword = (event) => {
        setPassword(event.target.value);
    };
    const passwordLogin = () => {
        loginUser(username, password).then(reloadPage).catch(reloadPage);
    };
    const onKeyPressEnter = (event) => {
        if (event.key === "Enter")
            passwordLogin();
    };
    return (_jsxs(Card, { sx: { backgroundColor: "#efefef", width: 350, mx: 10 }, children: [_jsx(CardContent, { children: _jsx(Typography, { gutterBottom: true, variant: "h5", component: "div", children: "Connexion" }) }), _jsx(CardActions, { children: _jsxs(Button, { onClick: () => window.location.assign("/microsoft/to-auth-redirect/"), children: ["Se connecter avec ", _jsx(MicrosoftLogo, { sx: { width: 110, pl: 1 } })] }) }), _jsx(Dialog, { open: open, children: _jsx(DialogContent, { children: _jsxs(Grid, { container: true, direction: "column", spacing: 1, children: [_jsx(Grid, { item: true, children: _jsx(TextField, { fullWidth: true, label: "Nom d'utilisateur", value: username, onChange: updateUsername, onKeyDown: onKeyPressEnter }) }), _jsx(Grid, { item: true, children: _jsx(TextField, { fullWidth: true, label: "Mot de passe", value: password, onChange: updatePassword, type: "password", onKeyDown: onKeyPressEnter }) }), _jsxs(Grid, { item: true, container: true, justifyContent: "space-between", children: [_jsx(Button, { onClick: () => window.location.assign("/admin/password_reset/"), children: "Mot de passe oubli\u00E9" }), _jsx(Button, { variant: "contained", onClick: passwordLogin, children: "Valider" })] })] }) }) })] }));
}
