import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import BadgeIcon from "@mui/icons-material/Badge";
import BakeryDiningIcon from "@mui/icons-material/BakeryDining";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DirectoryIcon from "@mui/icons-material/ContactPhone";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";
import DifferenceIcon from "@mui/icons-material/Difference";
import EuroIcon from "@mui/icons-material/Euro";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import HandymanIcon from "@mui/icons-material/Handyman";
import InsightsIcon from "@mui/icons-material/Insights";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MapIcon from "@mui/icons-material/Map";
import AdminIcon from "@mui/icons-material/MemorySharp";
import DocumentationIcon from "@mui/icons-material/MenuBook";
import PeopleIcon from "@mui/icons-material/People";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RuleIcon from "@mui/icons-material/Rule";
import SavingsIcon from "@mui/icons-material/Savings";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import TaskIcon from "@mui/icons-material/Task";
import CalendarIcon from "@mui/icons-material/Today";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, } from "@mui/material";
import { noRoles } from "../common/types/user";
import { DateQuantity } from "../common/util/date";
import { MomentUtils } from "../common/util/date/moment";
const dateUtils = MomentUtils.Instance;
const getYearSixMonthsAgo = () => dateUtils.alter(undefined, -6, DateQuantity.MONTHS).getFullYear();
export function SidebarMenu({ open, onClose, width, user }) {
    const { roles, viewClientIdentity, viewClientsList, viewSepaMandates, inLegalFollowUpGroup, inPaymentsGroup, hasTableauUser, } = user;
    const [sup, ec, adm, rg, rt, rd, rj, rs, sec, rh, it] = Object.values(Object.assign(Object.assign({}, noRoles), roles));
    /** All filters include super user */
    const headManagers = rg || rj || rs || ec || sup;
    const headManagersAndAdministrative = headManagers || adm;
    const administrativeAndLegalManager = rj || adm || ec || sup;
    const mainManagers = rg || rt || rd || ec || sup;
    const mainManagersAndSecretary = mainManagers || sec;
    const mainAndLegalManagers = mainManagers || rj;
    const mainLegalManagersAndSecretary = mainAndLegalManagers || sec;
    const administrative = adm || sup;
    const administrativeAndExperts = administrative || ec;
    const humanResources = rh || sup;
    const legalFollowUps = inLegalFollowUpGroup || mainAndLegalManagers;
    const sepaMandates = administrative || viewSepaMandates;
    const payments = inPaymentsGroup || sup;
    const clientIdentity = mainLegalManagersAndSecretary || viewClientIdentity;
    const itDepartment = it || sup;
    return (_jsxs(Drawer, { variant: "permanent", sx: [
            (theme) => ({
                "& .mm-MuiDrawer-paper": {
                    position: "relative",
                    whiteSpace: "nowrap",
                    width,
                    transition: theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                },
            }),
            !open &&
                ((theme) => ({
                    "& .mm-MuiDrawer-paper": {
                        overflowX: "hidden",
                        transition: theme.transitions.create("width", {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                        width: theme.spacing(7),
                        [theme.breakpoints.up("sm")]: {
                            width: theme.spacing(9),
                        },
                    },
                })),
        ], open: open, children: [_jsx(Box, { sx: (theme) => (Object.assign({ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "0 8px" }, theme.mixins.toolbar)), children: _jsx(IconButton, { onClick: onClose, size: "large", children: _jsx(ChevronLeftIcon, {}) }) }), [
                [
                    ["Accueil", _jsx("img", { src: "/static/img/logo-batt.png", alt: "Accueil", style: { height: 24, width: 24 } }), "/"],
                    ["Mon Espace", _jsx(DashboardIcon, {}), "/my-space/", false, false],
                    ["Clients", _jsx(PeopleIcon, {}), "/gestion_api/creationclientrapide/?ferme__exact=0", !viewClientsList],
                    ["Annuaire", _jsx(DirectoryIcon, {}), "/annuaire/136549/"],
                    ["Heures", _jsx(CalendarIcon, {}), "/gestion_api/heures/"],
                    ["Tableau", _jsx(InsightsIcon, {}), "/tableau-display/", !hasTableauUser],
                    ["Admin", _jsx(AdminIcon, {}), "/gestion_api/", !sup],
                    ["Debug", _jsx(BakeryDiningIcon, {}), "/debug-page/", !itDepartment],
                    ["Absences", _jsx(BeachAccessIcon, {}), "/leave-management/", false, false],
                ],
                [
                    ["Facture pièce", _jsx(ReceiptIcon, {}), "/gestion_api/facture/", !administrative],
                    ["Facture ligne", _jsx(DensitySmallIcon, {}), "/gestion_api/factureligne/", !administrative],
                    ["Paiements", _jsx(SavingsIcon, {}), "/gestion_api/paiement/", !payments],
                    ["Acomptes", _jsx(ScheduleSendIcon, {}), "/gestion_api/acompte/", !administrative],
                    [
                        "Relances",
                        _jsx(AssignmentLateIcon, {}),
                        "/gestion_api/clientrelance/?soldesup0=sup0",
                        !administrativeAndExperts,
                    ],
                    ["Missions clients", _jsx(ViewListIcon, {}), "/gestion_api/missionclient/?statut__id__exact=1", !administrative],
                    ["Mandats SEPA", _jsx(PlaylistAddCheckIcon, {}), "/gestion_api/mandatsepa/", !sepaMandates],
                    ["Collaborateurs", _jsx(PeopleAltIcon, {}), "/pretty-grid/?dataSet=assistants", !humanResources],
                    ["Onboarding", _jsx(FlightLandIcon, {}), "/gestion_api/onboardingcheck/?complete=0", !humanResources],
                    ["Offboarding", _jsx(FlightTakeoffIcon, {}), "/gestion_api/offboardingcheck/?complete=0", !humanResources],
                    ["Recap missions", _jsx(DifferenceIcon, {}), "/gestion_api/recapmissionclient/?encours=oui", !headManagers],
                    [
                        "Fiche liaison juridiques",
                        _jsx(AssignmentIndIcon, {}),
                        "/gestion_api/ficheliaisonjuridique/",
                        !mainAndLegalManagers,
                    ],
                    [
                        "Suivi juridique",
                        _jsx(RuleIcon, {}),
                        `/gestion_api/legalfollowup/?year=${getYearSixMonthsAgo()}`,
                        !legalFollowUps,
                    ],
                    ["Plaquette", _jsx(LibraryBooksIcon, {}), "/gestion_api/plaquettemission/", !mainLegalManagersAndSecretary],
                    ["Suivi LAB", _jsx(BadgeIcon, {}), "/gestion_api/suivilab/", !clientIdentity],
                    ["Cartographie LAB", _jsx(MapIcon, {}), "/pretty-grid/?dataSet=annual-risks", !clientIdentity],
                    ["Suivi outils", _jsx(HandymanIcon, {}), "/gestion_api/suivioutils/", !mainManagersAndSecretary],
                    ["Fiche de facturation", _jsx(EuroIcon, {}), "/gestion_api/fichefacturation/", !headManagersAndAdministrative],
                    ["Frais missions", _jsx(PriceCheckIcon, {}), "/gestion_api/fraismission/", !administrativeAndLegalManager],
                    ["Gestion des stocks", _jsx(Inventory2Icon, {}), "/gestion_api/produit/", !administrative],
                    ["Suivi des contrats", _jsx(TaskIcon, {}), "/gestion_api/contrat/", !administrative],
                ],
                [["Documentation", _jsx(DocumentationIcon, {}), "/documentation/index"]],
                /** [Title, Icon, Link, (Hidden), (Disabled)] list in a divider separated list */
            ].map((list, i) => (_jsxs("div", { children: [_jsx(Divider, {}), _jsx(List, { children: list
                            .filter((parts) => !parts[3])
                            .map(([title, icon, link, , disabled]) => (_jsx(ListItem, { disablePadding: true, sx: { display: "block" }, children: _jsxs(ListItemButton, { href: link !== null && link !== void 0 ? link : "/", disabled: disabled, sx: {
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }, children: [_jsx(ListItemIcon, { sx: {
                                            minWidth: 0,
                                            mr: open ? 3 : "auto",
                                            justifyContent: "center",
                                        }, children: icon }), _jsx(ListItemText, { primary: title, sx: { opacity: open ? 1 : 0 } })] }, title) }, title))) })] }, i)))] }));
}
